import * as React from 'react';
import { styled } from 'naan/stitches.config';
import { paletteKey } from '../../../study/views/study-palette';
// import { SimpleNotationsListPanel } from 'study/views/notations/simple-notations-list';
import { SentenceId } from '@tikka/basic-types';
import { ReviewSentenceExtras } from './review-sentence-extras';
import { Button } from '@naan/primitives/button';
import { AnimatePresence } from 'framer-motion';

import __ from '@core/lib/localization';

const L2Wrapper = styled('div', {
  position: 'relative',
  borderRadius: '16px',
  transition: 'opacity .3s, background-color .15s, transform .3s',
  textStyle: 'serif',
  cursor: 'default',
  userSelect: 'none',
  backgroundColor: paletteKey('$$sentence__backgroundColor_current'),
  boxShadow: '0px 1px 2px rgb(0 0 0 / 20%)',

  '&:not(.current).visited:hover': {
    backgroundColor: paletteKey('$$sentence__backgroundColor_hover'),
  },

  '& .show-words-container': {
    position: 'absolute',
    right: '0',
    top: '-48px',
    zIndex: '1',
  },

  '&.is-revealed .show-words-container': {
    transition: 'opacity .3s',
    opacity: 0,
    pointerEvents: 'none',
  },

  // '.playing &.current .show-words-container': {
  //   transition: 'opacity ease-in 2s',
  // },

  // '&:not(.current) .show-words-container, &.hide-show-words-btn .show-words-container':
  //   {
  //     opacity: 0,
  //     visibility: 'hidden',
  //     pointerEvents: 'none',
  //   },

  // [`& ${UnredactButton}`]: {
  //   transition: 'opacity .3s',
  //   opacity: 0,
  //   pointerEvents: 'none',
  // },

  // [`&.expose-unredact-sentence ${UnredactButton}`]: {
  //   opacity: 1,
  //   pointerEvents: 'all',
  // },

  '& .l2-and-translation': {
    position: 'relative',
  },

  '& .l2': {
    padding: '13px 16px 15px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    position: 'relative',
  },

  // '& > .card > .ext': {
  //   // display: 'none',
  //   // display: 'grid',
  //   // gridTemplateRows: '0fr',
  //   // transition: 'grid-template-rows .12s',
  //   // '& > div': {
  //   //   gridRow: '1 / span 2',
  //   //   overflow: 'hidden',
  //   // },
  // },
});

const ShowWordsWrapper = styled('div', {
  padding: '8px 0',
  '& > .inner': {
    backgroundColor: paletteKey('$$sentence__backgroundColor_current'),
    boxShadow: '0px 1px 2px rgb(0 0 0 / 20%)',
    borderRadius: '20px',
  },
});

type WrapperProps = React.ComponentProps<typeof L2Wrapper>;

export const StyledSentence: React.FC<
  WrapperProps & {
    translation: string;
    sentenceId: SentenceId;
    onUnredact: () => void;
  }
> = ({ children, translation, sentenceId, onClick, onUnredact, ...props }) => {
  const [hideButton, setHideButton] = React.useState(false);
  const handleUnredact = () => {
    setHideButton(true);
    onUnredact();
  };
  return (
    <L2Wrapper
      {...props}
      className={props.className + ' line script-sentence-container'}
    >
      <div className="show-words-container">
        <AnimatePresence>
          {hideButton ? null : (
            <ShowWordsWrapper onClick={handleUnredact}>
              <div className="inner">
                <Button
                  label={__('Show other words', 'showOtherWords')}
                  size="small"
                  presentation="white"
                />
              </div>
            </ShowWordsWrapper>
          )}
        </AnimatePresence>
      </div>
      <div className="card">
        <div className="l2-and-translation">
          <div className="l2" onClick={onClick}>
            {children}
          </div>
        </div>
        <div className="ext">
          <ReviewSentenceExtras
            sentenceId={sentenceId}
            translation={translation}
          />
        </div>
      </div>
    </L2Wrapper>
  );
};
