import React from 'react';
import { observer } from 'mobx-react';
import { CenterColumnLayout } from 'components/layouts/center-column-layout';
import { QuickLinks } from 'components/account/quick-links';
import { MediumGap, LargeGap } from '@naan/primitives/spacer/gaps';
// import { SoundbiteEtAlWidgetGroup } from './soundbite-et-al-widget-group';
import { DeepScrollRestoration } from 'lib/scroll-utils/deep-scroll-restoration';
import { useLocation } from 'react-router-dom';
// import { SampleStories } from './sample-stories';
import { FeaturedContent } from './featured-content';
import { UnauthenticatedDashboardNav } from './unauthenticated-dashboard-nav';
import { getMainScrollingContainer } from 'lib/scroll-utils';
import { TeacherWelcomeMessage } from '../welcome-message';
import { AboutRadioAmbulante } from './about-ra';
import { LearnDashboardHeading } from './dashboard-heading';
import { AppFactory } from '@app/app-factory';
import { SectionHeading } from './dashboard-section-heading';
import { Divider } from '@naan/primitives/divider';
import { StoryCard } from 'components/stories/story-card';
import { FreeStoryWidget } from './free-story-widget';
import { LastCompletedWidget } from './completed-widget';
import { ChannelBanner } from './channel-banner';
import { DashboardSubscribeCta } from './subscribe-cta';
import { DashboardWidgets } from './dashboard-widgets';
import { ClassroomWidgetList } from './classroom-widget-list';
import { GlobalFooter } from 'components/global-footer';
import { WidgetColumnContainer } from './widget-column-container';
import { CountdownNotice } from 'components/countdown-notice';
import { NewStoryWidget } from './new-story-widget';

import __ from '@core/lib/localization';

const maybeScrollToFragment = () => {
  const fragment = window.location.hash;
  if (fragment) {
    const element = document.getElementById(fragment.replace('#', ''));

    if (element) {
      const scrollingContainer = getMainScrollingContainer();
      if (scrollingContainer) {
        scrollingContainer.scrollTo({
          top: element.offsetTop - scrollingContainer.offsetTop - 56,
          behavior: 'smooth',
        });
      }
    }
  }
};

const WereYouWereWidget = observer(() => {
  const { storyManager } = AppFactory.root;

  const { featuredInProgress } = storyManager;

  if (!featuredInProgress) {
    return null;
  }

  return (
    <>
      <SectionHeading title={__('Where you were', 'whereYouWere')} />
      <Divider />
      <StoryCard story={featuredInProgress} truncateDescription={true} />
      <LargeGap />
    </>
  );
});

export const TrialDashboard = observer(() => {
  const location = useLocation();
  const { storyManager, userManager, raBranding } = AppFactory.root;

  const { showFreeStoryWidget, featuredInProgress } = storyManager;
  const featuredCompleted = !featuredInProgress
    ? storyManager.featuredCompleted
    : null;
  const { legacySoundbiteEngagement } = userManager.userData;

  // const raBranding = !storyManager.isMultiChannel;

  React.useEffect(() => {
    requestAnimationFrame(maybeScrollToFragment);
  }, [location]);

  // const freeStory = AppFactory.root.storyManager.storyForUnitSlug('miedo');

  return (
    <>
      <DeepScrollRestoration pageKey={location.pathname} />
      <UnauthenticatedDashboardNav />

      <CountdownNotice />
      <LearnDashboardHeading />

      <CenterColumnLayout>
        <TeacherWelcomeMessage />
      </CenterColumnLayout>
      {showFreeStoryWidget ? <FreeStoryWidget /> : null}
      <WidgetColumnContainer
        className={showFreeStoryWidget ? 'with-free-story-widget' : ''}
      >
        {/* <TrialWelcomeMessage /> not currently needed */}

        <ClassroomWidgetList />
        <WereYouWereWidget />
        {featuredCompleted ? (
          <>
            <LastCompletedWidget story={featuredCompleted} />
            <MediumGap />
          </>
        ) : null}
        {legacySoundbiteEngagement ? (
          <>
            <DashboardWidgets />
            <MediumGap />
          </>
        ) : null}
      </WidgetColumnContainer>
      <ChannelBanner />
      <DashboardSubscribeCta />
      <CenterColumnLayout backgroundColor="#ffeded">
        <MediumGap />
        <NewStoryWidget hideFloatingButton />
        <FeaturedContent />
        <LargeGap />
      </CenterColumnLayout>
      {raBranding ? (
        <CenterColumnLayout>
          <MediumGap />
          <AboutRadioAmbulante />
        </CenterColumnLayout>
      ) : null}
      <CenterColumnLayout>
        <MediumGap />
        <QuickLinks />
        <LargeGap />
        <GlobalFooter />
      </CenterColumnLayout>
      {/* <NewsletterDialog /> */}
    </>
  );
});
