// codemod-ignore-file
import * as React from 'react';
import * as Dialog from '@naan/primitives/modals/dialog';
import { observer } from 'mobx-react';
import { AppFactory } from 'app/app-factory';
import { styled } from '@naan/stitches.config';
import { Button } from '@naan/primitives/button';
import { paletteKey } from './study-palette';
import { HeadphonesIcon } from '@naan/icons/headphones-icon';
import { CircleExtraExtraLargeIcon } from '@naan/icons/circle-icon';
import { VSpacer } from '@naan/primitives/spacer';
import { Text } from '@naan/primitives/text';
import { useReaction } from '@common/hooks/use-reaction';
import { SCRIPT_SCROLLING_CONTAINER_ID } from 'player/views/player-ui-components';
import { useReturnFromStudy } from 'lib/hooks/use-navigate-to-study';
import { useLineReconciler } from 'player/views/player-ui-model-setup';
import { msToPoints } from '@core/models/user-manager/listening-stats';
import { bugsnagNotify } from '@app/notification-service';
import { DialogHtmlContainer } from 'components/ui/dialog-html-container';
import { VStack } from '@naan/primitives/stack';
import { scrollToCurrentLine } from './fx/scrolling';
import {
  PointsCardWrapper,
  PointsRow,
  StreakRow,
  SummaryRow,
} from 'components/ui/player-end-card/points-card';
import { EndCardHeader } from 'components/ui/player-end-card/end-card-header';
import { ellipsis } from '@utils/string-utils';
import { millisToPrettyDuration } from '@core/lib/pretty-duration';

import __ from '@core/lib/localization';

const Wrapper = styled('div', {
  $$backgroundColor: paletteKey('$$endCard__backgroundColor'),
  $$primaryColor: paletteKey('$$endCard__primaryColor'),
  $$secondaryColor: paletteKey('$$endCard__secondaryColor'),
  $$dividerColor: paletteKey('$$endCard__dividerColor'),
  backgroundColor: '$$backgroundColor',
  color: '$$primaryColor',
  padding: '0 24px 16px 24px',
  width: 'calc(100% + 32px)',
  transition: 'opacity 0.3s ease-out',
  marginLeft: -16,
  // minHeight: 504,
  '.playing &': {
    opacity: 0.3,
  },

  '@widerThanScript': {
    marginLeft: 0,
    marginBottom: '24px',
    padding: '0 16px 16px 16px',
    width: 600,
    borderRadius: 16,
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.2)',
  },

  '& > .heading': {
    display: 'flex',
    padding: '64px 0',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textStyle: 'medium-heading',
    color: '$gray-400',
    '& > .icon': {
      color: '$gray-400',
      '&.complete': {
        color: '$green-500',
      },
    },
  },

  '& > .row-title': {
    color: '$$secondaryColor',
    textStyle: 'tiny-text',
    textTransform: 'uppercase',
    borderTop: '1px solid $$dividerColor',
    paddingTop: '8px',
  },

  '& > .row': {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    padding: '12px 0 8px 0',

    '& > .left': {
      display: 'flex',
      alignItems: 'center',
      textStyle: 'medium-heading',
      '& > .icon': {
        marginRight: '8px',
        '&.clock': {
          color: '$teal-500',
        },
        '&.points': {
          color: '$yellow-500',
        },
      },
    },
    '& > .right': {
      color: '$$secondaryColor',
    },
  },
  variants: {
    locked: {
      true: {
        opacity: 0.3,
      },
    },
    focussed: {
      true: {
        opacity: '1 !important',
      },
    },
  },
});

const SkipToEndConfirmationDialog = ({
  onDismiss,
  okAction,
}: {
  onDismiss: () => void;
  okAction: () => void;
}) => {
  const handleOkAndDismiss = React.useCallback(() => {
    onDismiss();
    okAction();
  }, [okAction, onDismiss]);

  return (
    <DialogHtmlContainer onDismiss={onDismiss}>
      <Dialog.HtmlHeading>
        {__('Skip to end of chapter', 'skipToEndOfChapte')}
      </Dialog.HtmlHeading>
      <Dialog.Body>
        <VStack>
          <Text>
            {__(
              'This will mark all sentences as played',
              'thisWillMarkAllSentencesAsPlayed'
            )}
          </Text>
          <VSpacer size={1} />
        </VStack>
      </Dialog.Body>
      <Dialog.ButtonsContainer>
        <Dialog.Button
          presentation={'cancel'}
          label={__('Cancel', 'cancel')}
          data-test-id="cancelButton"
          onClick={onDismiss}
          tabIndex={-1}
        />
        <Dialog.Button
          presentation={'teal'}
          label={__('Skip forward', 'skipForwar')}
          data-test-id="okButton"
          onClick={handleOkAndDismiss}
          autoFocus
        />
      </Dialog.ButtonsContainer>
    </DialogHtmlContainer>
  );
};

export const EndOfChapter = observer(() => {
  const returnFromStudy = useReturnFromStudy();
  const model = AppFactory.studyModel;
  const sessionPoints = msToPoints(model.millisPlayed);

  const { userData } = AppFactory.root.userManager;
  const { todayPoints, sevenDayPoints } = userData;

  // beware, the current session points are not yet recorded into the stats until the player session is exited
  const todayPointsWithSession = todayPoints + sessionPoints;
  const sevenDayPointsWithSession = sevenDayPoints + sessionPoints;

  const handleCardClick = React.useCallback(() => {
    if (model.isNotionallyComplete) {
      model.seekToFinalCard();
    } else {
      AppFactory.dialogPresenter.present(onDismiss => {
        return (
          <SkipToEndConfirmationDialog
            onDismiss={() => {
              onDismiss();
              /// @elliottjf, @frank this is not in the spec, but felt good to me as a cancel action
              scrollToCurrentLine();
            }}
            okAction={() => {
              model.seekToFinalCard();
            }}
          />
        );
      });
    }
  }, [model]);

  const { chapter } = model;
  if (!chapter) {
    return <>(missing chapter data)</>; // at least during development
  }

  const completed = model.completionReached;
  const reconciler = useLineReconciler();
  if (!reconciler) {
    bugsnagNotify('EndOfChapter - reconciler undefined');
    return null;
  }

  let className = reconciler.getJoinedMembershipStringForElement('OUTRO:');

  /// scroll to the end of the script whenever the card gains focus
  const ref = React.useRef<HTMLDivElement>(null);
  useReaction(
    () => model.completionReached && model.afterSpoken,
    gainedFocus => {
      if (gainedFocus) {
        const scriptPanel = document.getElementById(
          SCRIPT_SCROLLING_CONTAINER_ID
        );
        setTimeout(() => {
          scriptPanel.scrollTo({
            top: scriptPanel.scrollHeight,
            behavior: 'smooth',
          });
        }, 50);
      }
    }
  );

  if (!completed) {
    return (
      <Wrapper
        className={className}
        locked={true}
        focussed={false}
        onClick={handleCardClick}
        id={'_OUTRO:'}
        ref={ref}
      >
        <h3 className="heading listening">
          <span className="incomplete icon">
            <CircleExtraExtraLargeIcon width={80} height={80} />
          </span>
          {__('Chapter in progress', 'chapterInProgress') + ellipsis}
        </h3>
      </Wrapper>
    );
  }

  const title =
    model.startingMode === 'FLUENT_LISTEN'
      ? __('Listen complete', 'listenComplete')
      : __('Study complete', 'studyComplete');

  const prettyDurationPlayed = model.millisPlayed
    ? millisToPrettyDuration(model.millisPlayed)
    : '--';
  return (
    <>
      <Wrapper
        className={className}
        focussed={model.completionReached && model.afterSpoken}
        locked={false}
        onClick={handleCardClick}
        id={'_OUTRO:'}
        ref={ref}
      >
        <EndCardHeader title={title} />

        <PointsCardWrapper>
          <StreakRow />
          <PointsRow
            icon={<HeadphonesIcon />}
            title={__('%{duration} listened', 'durationListened', {
              duration: prettyDurationPlayed,
            })}
            points={msToPoints(model.millisPlayed)}
          />
          <SummaryRow
            todayPoints={todayPointsWithSession}
            sevenDayPoints={sevenDayPointsWithSession}
          />
        </PointsCardWrapper>

        <VSpacer size={3} />
        {/* {anonymous ? (
        <OnboardingSection />
      ) : ( */}
        {/* <> */}
        <Button
          label={__('Continue', 'continue')}
          onClick={returnFromStudy}
          presentation="teal"
          size="large"
          fullWidth
          disabled={!completed}
        />
        {/* fullAccess ? null : (
          <EndCardCta
            playerType={'study'}
            listenMode={model.fluentListenMode}
            backTo={learnStorySlugPath(chapter.story.slug)}
          />
        ) */}
        {/* </> */}
        {/* // )} */}
      </Wrapper>
    </>
  );
});
